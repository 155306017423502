<template>
  <div class="row-1 home-divider-text">
    <div class="home-divider-text__content text-style-center">
      <p class="m-top-16px text-l">
        {{ props.text }}
      </p>

      <nuxt-link
        :to="
          localePath({
            name: 'index',
          })
        "
        class="button-round-1 --orange m-top-32px"
      >
        <span>{{ $t('navigation.discover-mazarol') }}</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesHomeDividerText',
}
</script>

<script setup>
const props = defineProps({
  text: String,
})
</script>

<style lang="scss">
@import './style.scss';
</style>
